@import "~@wfp/ui/source/globals/scss/colors";
@import "~@wfp/ui/source/globals/scss/vars";

.select_option__active {
  font-weight: 600;
  //border: 3px solid red !important;
}

.wfp--react-select-container {
  .wfp--react-select__multi-value {
    //color: #fff;
    background: $ui-04;
  }

  /*.wfp--react-select__multi-value__label {
    color: #fff;
  }*/
}

.group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-badge {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
}

.option__additional {
  color: $text-01;
  font-weight: 600;
  margin-right: $spacing-xs;
  //width: 2.5srem;
  display: inline-block;
}

.select_option__summary {
  //border: 3px solid red;
  .option__additional {
    display: none;
  }
}
