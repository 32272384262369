@import "~@wfp/ui/source/globals/scss/colors";
@import "~@wfp/ui/source/globals/scss/vars";
@import "~@wfp/ui/source/globals/scss/layout";
@import "~@wfp/ui/source/globals/scss/_typography";

.content {
  margin-top: $spacing-md;
  margin-bottom: $spacing-xs;

  h2 {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-md;
  }

  p {
    margin-bottom: $spacing-md;
  }

  .wfp--blockquote__icon {
    //margin-left: 1.5rem;

    > img {
      width: 3em;
    }
  }
}

:global {
  .wfp--blockquote__icon {
    @include breakpointMax(bp--md--major) {
      display: none;
    }
  }
  .wfp--list__element__title {
    white-space: nowrap;
  }

  .content__notifications {
    @include breakpoint(bp--md--major) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$spacing-sm;
      margin-right: -$spacing-sm;

      > div {
        width: 50%;
        min-width: 50%;
        padding: 0 $spacing-sm;
      }
    }
  }

  .wfp--blockquote {
    .wfp--list {
      li {
        margin-top: 0.5rem;
      }
    }
  }

  .show--mobile {
    @include breakpoint(bp--md--major) {
      display: none;
    }
  }

  .show--desktop {
    @include breakpointMax(bp--md--major) {
      display: none;
    }
  }
}

.submitWrapper {
  display: flex;
  margin-bottom: $spacing-lg;
}
